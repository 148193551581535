<template>
<div>
    <div v-if="viewno==1">
        <div class="col-md-12">
            <div class="row" style="margin-top: 20px">
                <div class="col-md-6">
                <h3 class="page-header">
                    <i class="fa fa fa-calculator animated bounceInDown show-info"></i>
                    <span>Rejected Job List</span>
                </h3>
                </div>
                <div class="col-md-6 text-right">
                <button @click="refresh()" class="btn btn-refresh">
                    <i class="fa fa-refresh"></i>
                </button>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="panel-body ">                   
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-horizontal cascde-forms">

                                    <div class="form-group flex-row CHK">
                                        <label class="col-lg-4 col-md-3 control-label">Applicant Name</label>
                                        <div class="col-lg-8 col-md-9">
                                            <input class="form-control form-cascade-control input-small" v-model="name" type="text" value="" />
                                        </div>
                                    </div>
                                    <div class="form-group flex-row">
                                        <label class="col-lg-4 col-md-3 control-label">Qualification</label>
                                        <div class="col-lg-8 col-md-9">
                                            <select class="form-control form-cascade-control input-small" v-model="qualification"><option value="0">SELECT</option>
                                                <option v-for="option in getmastertype(QUALIFICATION_TYPE)"
                                                v-bind:key="option.labelid" v-bind:value="option.labelid"
                                                v-text="option.label" />
                                            </select>
                                        </div>
                                    </div>  
                                    <div class="form-group flex-row">
                                        <label class="col-lg-4 col-md-3 control-label">Remark</label>
                                        <div class="col-lg-8 col-md-9">
                                            <select class="form-control form-cascade-control input-small" v-model="remark"><option value="0">SELECT</option>
                                                <option value="1">Excellent</option>
                                                <option value="2">Average</option>
                                                <option value="3">Poor</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 CHK">
                                <div class="form-horizontal cascde-forms">
                                    <div class="form-group flex-row CHK">
                                        <label class="col-lg-4 col-md-3 control-label">Applicant Mobile</label>
                                        <div class="col-lg-8 col-md-9">
                                            <input class="form-control form-cascade-control input-small" v-model="mobileno" type="number"/>
                                        </div>
                                    </div>
                                    <div class="form-group flex-row">
                                        <label class="col-lg-4 col-md-3 control-label">Apply For</label>
                                        <div class="col-lg-8 col-md-9">
                                            <select class="form-control form-cascade-control input-small" v-model="position"><option value="0">SELECT</option>
                                                <option v-for="option in getmastertype(APPLY_FOR_POSITION)"
                                                v-bind:key="option.labelid" v-bind:value="option.labelid"
                                                v-text="option.label" />
                                            </select>          
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 flex-between-row">
                            <div class="col-md-3" style="">
                                <button type="submit" @click="clear()"  class="btn bg-primary text-white btn-md">Clear <i class='bx bx-x'></i></button>
                            </div>
                            <div class="col-md-9" style="">
                                <button type="submit" @click="refresh()"  class="btn bg-primary text-white btn-md">Search <i class='bx bx-search-alt-2'></i></button>
                            </div>
                            
                        </div>
                    </div>
            </div>
        </div>
        <div class="col-md-12 mt-20 pb-10 boxshow">
            <div class="flex-between-row">
                <div class="col-md-3 flex-between-row">
                    <div class="">
                        <strong>Show Rec.</strong>
                    </div>
                    <div>
                        <select
                        class="form-control col-mb-1"
                        v-model="noofrec"
                        id="rec"
                        @change="refresh()"
                        >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-12 pt-20 panel">
            <div class="flex-between-row panel-body">
                <div class="">
                    <span class="control-label">Total Applied:</span>
                    <span class="badge bg-seagreen">
                       {{ appliedcount }}</span>
                </div>
                <div class="">
                    <span class="control-label">Total Shortlisted:</span>
                    <span class="badge bg-success">
                       {{ shortlistcount }}</span>
                </div>
                <div class="">
                    <span class="control-label">Total Appointed:</span>
                    <span class="badge bg-success">
                       {{ appointedcount }}</span>
                </div>
                <div class="">
                    <span class="control-label">Total Rejected:</span>
                    <span class="badge bg-danger font-14">{{rejectedcount}}</span>
                </div>
                <div class="">
                    <span class="control-label">Total Male:</span>
                    <span class="badge bg-seagreen font-14">{{malecount}}</span>
                </div>
                <div class="">
                    <span class="control-label">Total Female:</span>
                    <span class="badge bg-seagreen font-14">{{femalecount}}</span>
                </div>
                <!-- <div class="col-md-2 text-end">
                    <input v-if="!generate"
                        type="button"
                        id="btnprint"
                        class="btn bg-success btn-xs text-white"
                        value="Go to Print"
                        @click="generateReport()"
                    />
                    <pdf-test v-if="generate" :scheduleloanemi="scheduleloanemi" :overdues="overduefetch" :comments="visitcomment"></pdf-test>
                </div> -->
            </div>
        
        </div>
        <div class="pt-10">
            <div class="table-responsive panel-body">
                <table class="table users-table table-condensed table-hover table-sm table-bordered">
                    <thead class="table-font">
                    <tr>
                        <th>#</th>
                        <th>Name</th>
                        <!-- <th>Zone</th> -->
                        <th>Address</th>
                        <th>Mobiles</th>
                        <th>Alt.Mobiles</th>
                        <th>Gender</th>
                        <th>Experience</th>
                        <th>Qualification</th>
                        <th>Apply For</th>
                        <th>Date of Application</th>
                        <th>Remark</th>
                        <th>Status</th>
                        <th>Refer By</th>
                        <!-- <th>Edit</th> -->
                        <th>Description</th>
                    </tr>
                </thead>
                    <reject-job-row v-for="(item,index) in jobs" :key="item.id" 
                    :item="item" :index="index" :currentpage="currentpage" :noofrec="noofrec" ></reject-job-row>
                </table>
            </div>
            <div class="col-md-12" style="margin-top: 20px">
                <nav aria-label="Page navigation example" style="text-align: right !important">
                    <paginate
                        style="margin: 0px !important; float: right"
                        :page-count="noofpages"
                        :click-handler="clickCallback"
                        :prev-text="'&laquo;'"
                        :next-text="'&raquo;'"
                        :container-class="'pagination'"
                    >
                    </paginate>
                </nav>
            </div>
        </div>
    </div>
    <!-- <jobs-form v-if="viewno==2"></jobs-form> -->
</div>
</template>
<script>
// import JobsListRow from './JobsListRow.vue';
import RejectJobRow from "./RejectJobRow.vue";
import Paginate from "vuejs-paginate";
import Constants from '../../components/utilities/Constants.vue';
// import JobsForm from './JobsForm.vue';
import { mapGetters } from 'vuex'
export default {
    mixins:[Constants],
    components:{RejectJobRow,Paginate},
    data() {
        return {
            jobs:[],
            noofrec:10,
            currentpage:1,
            rows:0,
            name:'',
            mobileno:'',
            qualification:0,
            position:0,
            address:'',
            remark:0,
            status:0,
            appliedcount:0,
            shortlistcount:0,
            appointedcount:0,
            rejectedcount:0,
            malecount:0,
            femalecount:0
        }
    },
    computed:{
        noofpages(){
            return Math.ceil(parseInt(this.rows) / parseInt(this.noofrec))
        },
        ...mapGetters(['viewno'])
    },
    mounted() {
        this.refresh();
    },
    methods: {
        clickCallback(currentpage) {
            this.currentpage = currentpage;
            this.$store.commit("assigncurrentpage", currentpage);
            this.refresh();
        },
        refresh(){
            let param={
                name:this.name,
                mobileno:this.mobileno,
                qualification:this.qualification,
                position:this.position,
                remark:this.remark,
                status:3
            }
            window.axios.post('api/recruit/fetch',param)
                .then((response) => this.processTotaltResponse(response.data))
                .catch((err) => {
                console.log('', err)
            });
            window.axios.post('api/recruit/fetch/count')
                .then((response) => this.processTotaltCount(response.data))
                .catch((err) => {
                console.log('', err)
            });
        },
        processTotaltCount(data){
            this.appliedcount=data.applied
            this.shortlistcount=data.shortlisted
            this.appointedcount=data.appointed
            this.rejectedcount=data.rejected
            this.malecount=data.male
            this.femalecount=data.female
        },
        processTotaltResponse(data){
            this.jobs=data.recruit
            this.rows=data.count
        },
        clear(){
            this.name=''
            this.mobileno=''
            this.qualificatio=0
            this.position=0
            this.address=''
            this.remark=0
            this.status=0
            this.refresh()
        }
    },
}
</script>